$('[data-scroll="#contato"]').click(function(e){
	e.preventDefault();

	let position =  $('#contato').offset();

	position = position.top;

	$('.bg-menu').fadeOut(600, function(){
		$(this).remove();
	});

	$('.topo-content').removeClass('active');
	$('html,body').removeClass('lock-scroll');

	$('body, html').animate({
		scrollTop: position
	}, 2000);
});