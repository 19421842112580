$('[data-toggle="menu"]').click(function(){
	let topo = document.querySelector('.topo');
	let bgMenu = document.createElement('div');

	$(bgMenu).addClass('bg-menu');

	$(bgMenu).click(function(){
		$('.topo-content').removeClass('active');
		$('html,body').removeClass('lock-scroll');

		$('.bg-menu').fadeOut(600, function(){
			$(this).remove();
		});
	});

	$('.topo-content').addClass('active');
	$('html,body').addClass('lock-scroll');

	topo.appendChild(bgMenu);

	$(bgMenu).fadeIn(600);
});