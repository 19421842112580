$.fn.lazyimage = function(){
	let jaLazyimage = false;

	function isLazyOnRect (elemento, dados){
		if(!elemento.loaded){
			
			if(elemento.getBoundingClientRect().top <= (window.innerHeight + 200)){
				$(elemento).find('.loader').remove();

				let img = `<img src="${dados.src}" alt="${dados.alt}" width="${dados.width}" height="${dados.height}" class="img-fluid">`;

				$(elemento).find('.fig-canvas').append(img);

				elemento.loaded = true;
			}

		}
	}

	return this.each(function(index, el){
		let width 	= $(this).attr('width');
		let height 	= $(this).attr('height');
		let alt 	= $(this).attr('alt');
		let src 	= $(this).attr('src');

		let scale 	= (height / width) * 100;

		$(this).css({
			'width' 	: width,
			'max-width' : '100%'
		});

		let template = `<div class="fig-container" style="padding-bottom:${scale}%">
			<div class="fig-canvas"><img src="assets/images/loading.gif" class="loader" alt="loading"/></div>
		</div>`;

		$(this).append(template);

		isLazyOnRect(el, {
			src		: src,
			alt 	: alt,
			width 	: width,
			height 	: height
		});

		$(window).scroll(function(){
			if(jaLazyimage) return;

			setTimeout(function(){
				jaLazyimage = false;
			}, 100);

			isLazyOnRect(el, {
				src		: src,
				alt 	: alt,
				width 	: width,
				height 	: height
			});
		});
	});
}

$('lazyimage').lazyimage();